import { gql } from "@apollo/client";

export const GET_HEADER_DATA = gql`
    query {
        header {
            site_name,
            instagram_link
        }
    }
`

export const GET_HOMEPAGE = gql`
    query {
        homepage {
            main_image {
                url
            },
            portrait_image {
                url
            },
            portrait_quote,
            home_page_about_me,
            home_page_about_me_picture{
                url
            },
            home_page_about_me_title
        },
        projects(sort: "sort_order:asc") {
            id,
            project_name,
            project_cover_image {
                url
            }
        }
    }
`

export const GET_PROJECTS_PAGE_DATA = gql`
    query {
        projectsPage {
            page_heading,
            page_header_image {
                url
            }
        },
        projects (sort: "sort_order:asc") {
            id,
            project_link_slug,
            project_name,
            project_cover_image {
                url
            },
            project_gallery (sort: "name:asc") {
                id,
                url,
                width,
                height
            }
        }
    }
`

export const GET_PRODUCT_DESIGN_PAGE_DATA = gql`
    query {
        productDesign {
            page_heading,
            page_header_image {
                url
            }
        },
        designs (sort: "sort_order:asc") {
            id,
            product_design_name,
            product_design_link_slug,
            product_design_cover_image {
                url
            },
            product_design_gallery (sort: "name:asc") {
                id,
                url,
                width,
                height
            }
        }
    }
    
`

export const GET_CONTACT_PAGE_DATA = gql`
    query {
    contactPage {
        page_heading,
        page_header_image {
            url
        },
        enquiries_email,
        enquiries_topic,
        enquiries_image {
            url
        },
        products_email,
        products_topic,
        products_image {
            url
        },
        contact_page_general_address_info,
        contact_page_general_contact_info
    }
}
`

export const GET_PROJECT_NAMES = gql`
  query {
    projects (sort: "sort_order:asc") {
      project_name
    }
  }
`