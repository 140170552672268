import { Link } from 'gatsby'
import React from 'react'
import { useQuery } from '@apollo/client';
import { GET_PROJECT_NAMES } from '../queries/queries'

const Footer = () => {

  const { data, loading, error } = useQuery(GET_PROJECT_NAMES);

  console.log(data)

  const footerProjectMapper = () => {
    return data.projects.map(project => {
      return (
        <li key={project.project_name} className="mt-2 inline-block mr-2 sm:block sm:mr-0">
          <Link 
            to={`/projects#${project.project_name}`} 
            className="text-grey hover:text-grey-dark"
          >
            {project.project_name}
          </Link>
        </li>
      )
    })
  }

  return (
    <footer className="bg-white w-full">
      <div className="container mx-auto px-6 py-12 md:flex md:justify-between md:items-center border-t-2">
        <div className="table w-full md:flex md:justify-evenly text-center">

          <div className="block sm:table-cell">
            <p className="uppercase text-grey text-sm sm:mb-6 font-garamond">Projects</p>
            <ul className="list-reset text-xs mb-6 font-nunito">
              {data && 
              footerProjectMapper()}
            </ul>
          </div>

          <div className="block sm:table-cell">
            <p className="uppercase text-grey text-sm sm:mb-6 font-garamond">Services</p>
            <ul className="list-reset text-xs mb-6 font-nunito">
              <li className="mt-2 inline-block mr-2 sm:block sm:mr-0">
                <a href="#" className="text-grey hover:text-grey-dark">Service1</a>
              </li>
              <li className="mt-2 inline-block mr-2 sm:block sm:mr-0">
                <a href="#" className="text-grey hover:text-grey-dark">Service2</a>
              </li>
            </ul>
          </div>
          
          <div className="block sm:table-cell">
            <p className="uppercase text-grey text-sm sm:mb-6 font-garamond">Links</p>
            <ul className="list-reset text-xs mb-6 font-nunito">
              <li className="mt-2 inline-block mr-2 sm:block sm:mr-0">
                <Link to="/" className="text-grey hover:text-grey-dark">Home</Link>
              </li>
              <li className="mt-2 inline-block mr-2 sm:block sm:mr-0">
                <Link to="/#about" className="text-grey hover:text-grey-dark">About</Link>
              </li>
              <li className="mt-2 inline-block mr-2 sm:block sm:mr-0">
                <Link to="/contact" className="text-grey hover:text-grey-dark">Contact</Link>
              </li>
            </ul>
          </div>

        </div>
      </div>
    </footer>
  )
}

export default Footer